<template>
  <b-container>
    <div>
      <b-row class="mt-4">
        <b-col md="12">
          <p class="text-center">
            <span
              class="aiops_neutral_blue_heading1"
              style="text-transform: uppercase;"
            >
              DATA SOURCES
            </span>
          </p>
          <p class="text-center">
            <span
              v-if="dataSourceItems.length < 1"
              class="aiops_paragraph_6f9ca8"
            >
              You need to have at least one Data Source configured to feed the solution with Ticket Data.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row class="float-lg-center">
        <b-col
          v-for="(dataSourceItem, index) in dataSourceItems"
          :key="index"
          class="col-sm-6 col-md-3"
        >
          <data-item
            v-if="dataSourceItems"
            class="mainDataSourceContainer"
            :dataInfo="{name: dataSourceItem.name, icon: dataSourceItem.icon}"
          >
            <template #drop-down-aiops>
              <b-dropdown
                v-if="dataSourceItem.name"
                size="sm"
                variant="link"
                no-caret
                class="dataSourceOptions"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="25"
                    class="optionsIcon"
                  />
                </template>
                <!-- <b-dropdown-item href="#">
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="20"
                  />
                  Info
                </b-dropdown-item> -->
                <!-- <b-dropdown-item
                  href="#"
                  @click="deleteData"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="20"
                  />
                  Delete
                </b-dropdown-item> -->
                <b-dropdown-item
                  href="#"
                  @click="editDataSource"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="20"
                  />
                  Edit
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </data-item>
        </b-col>
        <b-col
          v-if="dataSourceItems.length < 1"
          class="col-sm-6 col-md-3"
          @click="editDataSource"
        >
          <data-item />
        </b-col>
      </b-row>
      <b-row class="aiops_data_source_info_box p-1 mt-3 mb-3">
        <b-col class="col-md-12">
          <b-row class="p-1">
            <b-col class="col-md-6 text-center">
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
              <span
                class="aiops_data_source_info_title "
              >
                Last updated
              </span>
              <span
                class="aiops_data_source_info_data"
              >
                {{ lastMmodifiedDate }}
              </span>
            </b-col>
            <b-col class="col-md-6 text-center">
              <feather-icon
                icon="DatabaseIcon"
                class="mr-50"
              />
              <span
                class="aiops_data_source_info_data"
              >
                {{ total }}
              </span>
              <span
                class="aiops_data_source_info_title "
              >
                Records imported
              </span>
            </b-col>
          </b-row>
          <!-- <b-row class="p-1">
            <b-col class="col-md-6">
              <feather-icon
                icon="ClockIcon"
                class="mr-50"
              />
              <span
                class="aiops_data_source_info_title "
              >
                Response time
              </span>
              was
              <span
                class="aiops_data_source_info_data"
              >
                187 Miliseconds
              </span>
            </b-col>
            <b-col class="col-md-6">
              <feather-icon
                icon="AlertTriangleIcon"
                class="mr-50"
              />
              <span
                class="aiops_data_source_info_data"
              >
                23
              </span>
              <span
                class="aiops_data_source_info_title "
              >
                errors found
              </span>
              during import
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </div>
    <div>
      <modal-aiops-component
        title="ADD A NEW DATA SOURCE"
        :logo="logoModal"
      >
        <template v-slot:body>
          <validation-observer
            ref="aiopsConfigDataSource"
            @keyup.enter="validationForm"
          >
            <b-form>
              <b-row class="py-2 px-2">
                <b-col
                  md="12"
                  class="pl-4"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row class="pt-2">
                        <b-col
                          v-for="(data, index) in dataConfig"
                          :key="index"
                          md="12"
                        >
                          <b-form-group
                            v-if="data.type === 'password'"
                            class="aiops_label_form_bold"
                            :label="data.key|filterConnectorFieldsName"
                            :label-for="data.key|filterConnectorFieldsName"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="data.key|filterConnectorFieldsName"
                            >
                              <b-form-input
                                :id="index.toString()"
                                v-model="data.value"
                                :type="data.type"
                                autocomplete="off"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="data.key|filterConnectorFieldsName"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group
                            v-if="data.type !== 'password'"
                            class="aiops_label_form_bold"
                            :label="data.key|filterConnectorFieldsName"
                            :label-for="data.key|filterConnectorFieldsName"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="data.key|filterConnectorFieldsName"
                              rules="required"
                            >
                              <b-form-input
                                :id="index.toString()"
                                v-model="data.value"
                                autocomplete="off"
                                :state="errors.length > 0 ? false:null"
                                :placeholder="data.key|filterConnectorFieldsName"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- login button -->
                      <b-row
                        align-h="end"
                        class="margin-top-34"
                      >
                        <b-col
                          class="col-md-12 text-right"
                        >
                          <b-button
                            class="ibop-mineral-green-button"
                            @click="addConfig"
                          >
                            <b-img
                              class="mintui_arrow_right_circle_light"
                            />
                            <span class="align-middle aiops_label_white_14">Done</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </modal-aiops-component>
    </div>
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  VBModal,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { password, required } from '@validations'
import utilsAiops from '@/@core/utils/aiops/utils'
import store from '@/store'
import DataItem from './DataItem.vue'
import ModalAiopsComponent from '../../../../components/modal/modal-product/ModalAiopsComponent.vue'

const { loadingProccessSpinner, loading } = utilsAiops()

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    DataItem,
    BDropdown,
    BDropdownItem,
    ModalAiopsComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    filterConnectorFieldsName(val) {
      const newVal = val.replaceAll('_', ' ')
      return newVal
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logoModal: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      dataSourceItems: [{
        name: 'ServiceNow',
        icon: 'CpuIcon',
        // eslint-disable-next-line global-require
        logo: require('@/assets/images/aiops/logo/serviceNow.svg'),
      }],
      dataConfig: [],
      required,
      password,
      total: 0,
      lastMmodifiedDate: '',
      queryFilter: '',
      rowsLimit: '10',
      offSet: '0',
      sort: '',
    }
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }
    this.dataToDataSource()
    this.welcomeAssist()
  },
  methods: {
    dataToDataSource() {
      this.currentPage = 1
      const queryParams = {
        limit: this.rowsLimit,
        offset: this.offSet,
        query: this.queryFilter,
        sort: this.sort,
      }
      let queryTickets = ''
      Object.entries(queryParams).forEach(([key, queryValue]) => {
        if (queryValue.length > 0) {
          if (queryTickets.length === 0) {
            const queryparam = `${key}=${queryValue}`
            queryTickets = queryTickets.concat(queryparam)
            return
          }
          const queryparam = `&${key}=${queryValue}`
          queryTickets = queryTickets.concat(queryparam)
        }
      })
      store.dispatch('productAiops/dataToDataSource', queryTickets)
        .then(response => {
          const { total, lastMmodifiedDate } = response
          this.total = total
          this.lastMmodifiedDate = lastMmodifiedDate ? `on ${lastMmodifiedDate}` : 'Not available'
          return this.getConfigList()
        })
        .catch(() => {
          this.errorMessage()
          loading(false)
        })
    },
    getConfigList() {
      store.dispatch('productAiops/getConfigList')
        .then(res => {
          const { data } = res
          this.dataConfig = data
          loading(false)
        })
        .catch(() => {
          this.errorMessage()
        })
    },
    editDataSource() {
      this.$bvModal.show('modal-aiops')
    },
    addConfig() {
      this.$refs.aiopsConfigDataSource.validate().then(success => {
        if (success) {
          this.$bvModal.hide('modal-aiops')
          loadingProccessSpinner(true)
          const payloadConfig = this.dataConfig
          store.dispatch('productAiops/configService', payloadConfig)
            .then(() => {
              const variant = 'success'
              this.$bvToast.toast('The Data Source was correctly configured', {
                title: 'Aiops Data Source',
                variant,
                solid: true,
              })
              loadingProccessSpinner(false)
            })
            .catch(error => {
              this.errorMessage()
              loadingProccessSpinner(false)
              // this.errorRequest()
              this.$refs.aiopsConfigDataSource.setErrors(error)
            })
        }
      })
    },
    deleteData() {
      this.dataSourceItems.pop()
    },
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
  },
}
</script>

<style>
.mainDataSourceContainer {
  width: 80%;
}
.dataSourceAiops {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.float-lg-center {
  display: flex;
  justify-content: center;
}

.aiops_data_source_info_title {
  font-size: 14px;
  font-weight: bold;
  color: #3e7282;
}

.aiops_data_source_info_data {
  font-size: 14px;
  color:#00aa9b;
}

.aiops_data_source_info_box {
  background-color:rgb(221,237,238);
}
</style>
