<template>
  <b-container
    class="dataSourceContainer"
    :style="isSelected && dataInfo.name ? selectedBorder : ''"
    @click="selectData"
  >
    <slot name="drop-down-aiops" />
    <div
      v-if="isSelected && dataInfo.name"
      class="selectedIcon"
    >
      <feather-icon
        icon="CheckIcon"
        size="30"
      />
    </div>
    <b-row align-h="center">
      <b-col
        class="iconColumn"
        :style="isSelected && dataInfo.name ? selectedFont : ''"
      >
        <b-img
          v-if="dataInfo.name"
          class="dataSourceAiops"
          :src="require('@/assets/images/aiops/logo/serviceNow.svg')"
        />
        <feather-icon
          v-if="!dataInfo.name"
          :icon="dataInfo.icon"
          size="50"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="dataInfo.name"
      :style="isSelected && dataInfo.name ? selectedFont : ''"
    >
      <b-col
        class="nameColumn"
        :style="isSelected && dataInfo.name ? selectedFont : ''"
      >
        {{ dataInfo.name }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  BImg,
  BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BImg,
    BContainer,
  },
  props: {
    dataInfo: {
      type: Object,
      default() {
        return {
          icon: 'PlusCircleIcon',
          name: null,
          // eslint-disable-next-line global-require
          logo: require('@/assets/images/aiops/logo/serviceNow.svg'),
        }
      },
    },
  },
  data() {
    return {
      isSelected: false,
      selectedFont: {
        color: '#00AA9B',
      },
      selectedBorder: {
        'border-color': '#00AA9B',
      },
    }
  },
  created() {
    setTimeout(() => {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    }, 1000)
  },
  methods: {
    selectData() {
      if (this.dataInfo) {
        this.isSelected = !this.isSelected
      }
    },
  },
}
</script>

<style>

.dataSourceContainer {
  border: 1px solid #A7CCCE;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: space-around;
  border-radius: 0px;
  align-items: center;
  padding: 10% 0;
  position: relative;
}
.dataSourceOptions {
  position: absolute !important;
  right: -5%;
  top: 0;
}
.dataSourceOptions *,
.dataSourceOptions *:hover {
  color: #00AA9B;
}
.selectedIcon {
  position: absolute;
  left: -1px;
  top: -1px;
  background-color: #00AA9B;
  color: white;
  padding: 2% 5% 5% 2%;
  border-bottom-right-radius: 100%;
}
.iconColumn {
  cursor: pointer;
  color: #A7CCCE;
}
.iconColumn svg {
  stroke-width: 1.5px !important;
}
.nameColumn {
  font-weight: bold;
  font-size: 1.2em;
  color: #A7CCCE;
}
.dataSourceAiops {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 20px;
}
</style>
